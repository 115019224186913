import React from 'react';

import {
  ButtonPriority,
  ButtonSize,
  Card,
  Image,
  ImageResizeOptions,
  PaddingModes,
  Text,
} from 'wix-ui-tpa';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { OtherPayload, PayloadCmpProps } from '../ChatMessagePayload/types';
import { ChatCard } from '../ChatCard';
import { ImageBlock } from '../ImageBlock';
import { LinkButton } from '../ButtonLink';

import { classes, st } from './GenericPayload.st.css';

type GenericPayloadProps = PayloadCmpProps & {
  payload: OtherPayload;
};

export const GenericPayload: React.FC<
  GenericPayloadProps & React.HTMLAttributes<HTMLElement>
> = ({ isSmall, payload, ...rootElementAttrs }) => {
  const [t] = useTranslation();
  const { title, subtitle, description, price, img, url } = payload;

  const hasInfoBlock = !!(title || subtitle || description || price);

  if (!img && !url && !hasInfoBlock) {
    return null;
  }

  // Button
  if (url && !img) {
    return (
      <div className={`${st(classes.root)} ${rootElementAttrs.className}`}>
        <LinkButton
          link={url}
          title={title ?? url}
          priority={ButtonPriority.secondary}
          size={ButtonSize.tiny}
          // This prop is needed for ellipsis behavior
          paddingMode={PaddingModes.explicitPaddings}
        />
      </div>
    );
  }

  // Card
  return (
    <ChatCard
      {...rootElementAttrs}
      className={`${st(classes.root)} ${rootElementAttrs.className}`}
      isSmall={!!isSmall}
    >
      {img && (
        <Card.Container className={st(classes.section)}>
          <ImageBlock aspectRatio={1}>
            <Image
              className={st(classes.image)}
              src={img}
              resize={ImageResizeOptions.cover}
              aspectRatio="square"
            />
          </ImageBlock>
        </Card.Container>
      )}
      {hasInfoBlock && (
        <Card.Container className={st(classes.section)}>
          {title && <Text className={st(classes.title)}>{title}</Text>}
          {subtitle && (
            <Text className={st(classes.description)}>{subtitle}</Text>
          )}
          {description && (
            <Text className={st(classes.description)}>{description}</Text>
          )}
          {price && <Text className={st(classes.description)}>{price}</Text>}
        </Card.Container>
      )}
      {url && (
        <Card.Container className={st(classes.section)}>
          <LinkButton
            fullWidth
            link={url}
            title={t('app.widget.genericPayload.link')}
            size={ButtonSize.tiny}
            // This prop is needed for ellipsis behavior
            paddingMode={PaddingModes.explicitPaddings}
          />
        </Card.Container>
      )}
    </ChatCard>
  );
};
