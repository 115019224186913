import React, {
  ChangeEvent,
  useCallback,
  KeyboardEventHandler,
  useMemo,
  useEffect,
  useState,
} from 'react';

import { IconButton } from 'wix-ui-tpa';

import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { useAppSelector } from '@/store';
import {
  selectIsWidgetOnline,
  selectFlags,
  selectOfflineDisabledMessaging,
  selectShouldDisableInput,
} from '@/features';

import settingsParams from 'AiAssistantWidget/settingsParams';
import stylesParams from 'AiAssistantWidget/stylesParams';

import { SendIcon } from '../svg';

import { st, classes, cssStates } from './Input.st.css';

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  disabled?: boolean;
};

const getCharsFit = (text: string, font: string, maxWidth: number) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }

  context.font = font;

  let width = 0;
  let charCount = 0;

  for (const char of text) {
    width += context.measureText(char).width;
    if (width > maxWidth) {
      break;
    }
    charCount++;
  }

  return charCount;
};

export const Input = ({ value, onChange, onSubmit, disabled }: InputProps) => {
  const { t } = useTranslation();
  const { isEditor, isMobile } = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();
  const isOnline = useAppSelector(selectIsWidgetOnline);
  const offlineDisabledMessaging = useAppSelector(
    selectOfflineDisabledMessaging,
  );
  const shouldDisableInput = useAppSelector(selectShouldDisableInput);

  const isContact = useAppSelector(selectFlags.isContact);

  const [Textarea, setTextarea] =
    React.useState<React.ComponentType<any> | null>(null);

  const submitRef = React.useRef<any>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const [displayedPlaceholder, setDisplayedPlaceholder] = useState('');

  const inputPlaceholder = useMemo(() => {
    if (isOnline || isEditor) {
      return settings.get(settingsParams.textInputPlaceholder);
    }

    if (!isOnline && (isContact || !offlineDisabledMessaging)) {
      return t('app.settings.text.disabledInputTooltipForContact');
    }

    return t('app.settings.text.disabledInputTooltip');
  }, [isContact, isEditor, isOnline, settings, offlineDisabledMessaging, t]);

  const disabledInput = useMemo(
    () => disabled || (shouldDisableInput && !isEditor),
    [isEditor, disabled, shouldDisableInput],
  );

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    if (!Textarea) {
      // TODO: add skeleton loader
      import('react-textarea-autosize').then((module) => {
        setTextarea(() => module.default);
      });
    }
  }, [Textarea]);

  useEffect(() => {
    const el = submitRef.current?.wrappedComponentRef?.innerComponentRef;
    if (Textarea && el) {
      el.disabled = disabledInput;
      if (!disabledInput) {
        textareaRef.current?.focus();
      }
    }
  }, [Textarea, disabledInput]);

  useEffect(() => {
    if (!Textarea) {
      return;
    }

    /**
    Since Chrome & Edge no longer allow styling inside ::placeholder due to browser optimizations (like applying text-overflow: ellipsis)
    we need to calculate the number of characters that fit in the input content area to imitate ellipsis for placeholder
     */
    const fontParam = styles.get(stylesParams.inputTextFont);
    const fontFamilies = fontParam.family?.split(',');
    const font = `${fontParam.size}px ${fontFamilies?.[0] ?? 'sans-serif'}`;
    const inputHorizontalPadding = 70;
    const containerHorizontalPadding = 48;
    const contentWidth =
      styles.get(stylesParams.widgetWidth) -
      inputHorizontalPadding -
      containerHorizontalPadding;
    const chars = getCharsFit(inputPlaceholder, font, contentWidth);

    setDisplayedPlaceholder(inputPlaceholder.slice(0, chars - 3) + '...');
  }, [Textarea, inputPlaceholder, styles]);

  if (!Textarea) {
    return null;
  }

  return (
    <div
      className={st(
        classes.root,
        cssStates({ disabled: disabledInput, isMobile }),
      )}
    >
      <div className={st(classes.inputContainer)}>
        <Textarea
          value={value}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          maxRows={6}
          className={st(classes.input)}
          placeholder={displayedPlaceholder}
          disabled={disabledInput}
          autoFocus={!isEditor}
          ref={textareaRef}
          InputProps={{}}
        />
        <div className={st(classes.submit)} onClick={onSubmit}>
          <IconButton
            aria-disabled={disabledInput}
            disabled
            innerRef={submitRef}
            icon={
              <SendIcon
                className={st(
                  classes.submitIcon,
                  cssStates({ emptyInput: !value.length }),
                )}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
